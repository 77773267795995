import {
  type GetPropertiesOptions,
  type PlaceLocation,
  type PropertiesResponse,
  type SearchGeoPoint,
} from '@ev/search-modules-api';
import {
  type SearchModuleFilters,
  SearchModule,
} from '@ev/search-modules-components';
import {
  type BusinessDivisionType,
  getDivisionContext,
  getPageContext,
  getPropertyFilterContext,
  getShopContext,
  getSortContext,
} from '@ev/snowplow-library';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { type CommonPageProps } from 'pages/_app';
import { type ReactElement, useState } from 'react';

import { Head } from '@/components/common/Head/Head';
import { DEFAULT_MAP_BOUNDING_BOX } from '@/components/common/Search/constants';
import { Skeleton } from '@/components/common/Skeleton';
import { ImageComponent } from '@/components/redesign/elements/ImageComponent/ImageComponent';
import { metaDescriptionsIndex } from '@/core/const/metaDescriptions';
import {
  useGAEvent,
  useGAEventOnce,
  useGTMTackingIdsEvents,
} from '@/core/hooks/useGAEvent';
import { useGTMDataLayer } from '@/core/hooks/useGTMDataLayer';
import { useMapFeature } from '@/core/hooks/useMapFeature';
import { useSettings } from '@/core/hooks/useSettings';
import { useTrackPageView } from '@/core/hooks/useTrackPageView';
import { useWatchlist } from '@/core/hooks/useWatchlist';
import { config } from '@/core/service/apiClient';
import { PageTypeEnum } from '@/core/types/tracking.types';
import {
  getSortValues,
  mapSearchStateToFilterContext,
} from '@/core/utils/tracking/tracking';

import { StyledContainer, StyledPageContainer } from './Search.styles';

type SearchAppProps = {
  initialFilters: SearchModuleFilters;
  initialPlaceDetails?: PlaceLocation;
  initialResults?: PropertiesResponse;
  initialGeoResults?: SearchGeoPoint[];
  initialOptions?: GetPropertiesOptions;
  shopNames: string[];
  hasLicencePartnerGAAccount: boolean;
  isSearchAlertEnabled: boolean;
  isLipaPortfolioPage: boolean;
  isMapEnabled: boolean;
};

export const SearchApp = ({
  isSnowplowTrackerInit,
  hasLicencePartnerGAAccount,
  spearHeadNavigation,
  pageLanguages,
  isLipaPortfolioPage,
  initialFilters,
  initialOptions,
  initialPlaceDetails,
  initialResults,
  initialGeoResults,
  shopNames,
  isMapEnabled: isMapEnabledProp,
}: SearchAppProps & CommonPageProps): ReactElement => {
  const { t } = useTranslation('common');
  const { currency, measurementSystem, language, countryCode } = useSettings();
  const { toggleWatchlistItem, watchlist } = useWatchlist();
  const [isMapView, setIsMapView] = useState(false);

  const { isMapEnabled } = useMapFeature(isMapEnabledProp);

  // TODO:  handle within SearchModule
  useTrackPageView(isSnowplowTrackerInit, [
    getPageContext({
      isHQPage: true,
      type: isLipaPortfolioPage
        ? PageTypeEnum.LIPA_SEARCH_RESULT
        : PageTypeEnum.SEARCH_RESULT,
    }),
    getPropertyFilterContext(
      mapSearchStateToFilterContext(initialFilters, currency, measurementSystem)
    ),
    getSortContext({ ...getSortValues(initialFilters.sortingOptions?.[0]) }),
    getDivisionContext({
      name: initialFilters.businessArea[0] as BusinessDivisionType,
    }),
    getShopContext({ id: initialFilters.shopIds?.[0] ?? '' }),
  ]);

  useGTMTackingIdsEvents();
  useGAEventOnce('searchResult');
  useGAEvent('searchResultLicensePartner', hasLicencePartnerGAAccount);
  useGTMDataLayer('type', 'SEARCH_RESULTS_NEW');

  return (
    <StyledPageContainer
      $isHeaderFixed={isMapView}
      $isLegacyHeader={!spearHeadNavigation?.headerData}
    >
      <Head
        title={t('search.head.index.title')}
        description={t(
          'search.head.index.description',
          metaDescriptionsIndex(t, initialFilters, initialPlaceDetails?.name)
        )}
        index={false}
      />
      <Skeleton
        spearHeadNavigation={spearHeadNavigation}
        pageLanguages={pageLanguages}
        withContactButton
        filters={initialFilters}
        stickyHeader={false}
        isPropertySearchPage
      >
        <StyledContainer $isLegacyHeader={!spearHeadNavigation?.headerData}>
          <SearchModule
            countryCode={countryCode}
            initialFilters={initialFilters}
            initialOptions={initialOptions}
            initialPlaceDetails={initialPlaceDetails}
            initialResults={initialResults}
            initialGeoResults={initialGeoResults}
            currency={currency}
            measurementSystem={measurementSystem}
            language={language}
            apiConfig={config}
            translationResolver={t as () => string}
            imageComponent={ImageComponent}
            linkComponent={(props) => <Link {...props} />}
            watchlist={watchlist}
            events={{
              onWatchlistClick: (property) =>
                toggleWatchlistItem(
                  property,
                  PageTypeEnum.SEARCH_RESULT,
                  false
                ),
              onIsMapViewChange: setIsMapView,
            }}
            searchAlertBaseUrl={
              (process.env.NEXT_PUBLIC_VERCEL_DOMAIN ?? '') + '/api/'
            }
            tracking={{
              snowplowApplicationId: 'new-search',
              isSnowplowInitialized: isSnowplowTrackerInit,
            }}
            shopNames={shopNames}
            isMapEnabled={isMapEnabled}
            mapTilerApiKey={process.env.NEXT_PUBLIC_MAP_TILER_API_KEY}
            friendlyCaptchaSiteKey={
              process.env.NEXT_PUBLIC_FRIENDLY_CAPTCHA_SITE_KEY!
            }
            vercelProtectionBypassToken={
              process.env.NEXT_PUBLIC_VERCEL_PROTECTION_BYPASS_TOKEN
            }
            mapBoundingBoxFallback={DEFAULT_MAP_BOUNDING_BOX}
            isFilterBarSticky={!!spearHeadNavigation?.headerData}
          />
        </StyledContainer>
      </Skeleton>
    </StyledPageContainer>
  );
};
