import {
  type GetPropertiesOptions,
  type PlaceLocation,
  type PropertiesResponse,
  type SearchGeoPoint,
} from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@ev/search-modules-components';
import { type CommonPageProps } from 'pages/_app';
import { type ReactElement, useEffect } from 'react';

import { SearchApp } from '@/components/views/Search/Search';
import { useSettings } from '@/core/hooks/useSettings';
import { useGTMTracking } from '@/core/hooks/usGTMTracking';
import { sendTrackSiteSearchEvent } from '@/core/utils/tracking/tracking';

export type SearchResultsPageProps = {
  initialFilters: SearchModuleFilters;
  initialResults?: PropertiesResponse;
  initialGeoResults?: SearchGeoPoint[];
  initialPlaceDetails?: PlaceLocation;
  initialOptions: GetPropertiesOptions;
  hasLicencePartnerGAAccount: boolean;
  shopNames: string[];
  isLipaPortfolioPage: boolean;
  isMapEnabled: boolean;
};

export const SearchResultsPage = ({
  isSnowplowTrackerInit,
  hasLicencePartnerGAAccount,
  spearHeadNavigation,
  pageLanguages,
  initialResults,
  initialGeoResults,
  initialFilters,
  initialOptions,
  initialPlaceDetails,
  shopNames,
  isLipaPortfolioPage,
  isMapEnabled,
}: SearchResultsPageProps & CommonPageProps): ReactElement => {
  const { currency, measurementSystem } = useSettings();

  useEffect(() => {
    if (isSnowplowTrackerInit && initialResults) {
      sendTrackSiteSearchEvent({
        results: { ...initialResults },
        filters: initialFilters,
        currency,
        measurementSystem,
        shopNames,
        placeName: initialPlaceDetails?.name ?? '',
      });
    }
  }, [
    currency,
    initialFilters,
    initialPlaceDetails,
    initialResults,
    isSnowplowTrackerInit,
    measurementSystem,
    shopNames,
  ]);

  useGTMTracking();

  return (
    <SearchApp
      pageLanguages={pageLanguages}
      spearHeadNavigation={spearHeadNavigation}
      hasLicencePartnerGAAccount={hasLicencePartnerGAAccount}
      isSnowplowTrackerInit={isSnowplowTrackerInit}
      initialFilters={initialFilters}
      initialOptions={initialOptions}
      initialPlaceDetails={initialPlaceDetails}
      initialResults={initialResults}
      initialGeoResults={initialGeoResults}
      isSearchAlertEnabled={!isLipaPortfolioPage}
      isLipaPortfolioPage={isLipaPortfolioPage}
      shopNames={shopNames}
      isMapEnabled={isMapEnabled}
    />
  );
};
