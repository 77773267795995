import { useEffect, useMemo } from 'react';
import { useCookie } from 'react-use';

import { MAP_ENABLED_COUNTRIES } from '@/components/common/Search/constants';

import { useSettings } from './useSettings';

export const useMapFeature = (isMapEnabledProp: boolean) => {
  const [mapCookie, updateMapCookie] = useCookie('ns-map-enabled');
  const { countryCode } = useSettings();

  useEffect(() => {
    if (window.location.hash === '#ns-map-disabled') {
      updateMapCookie('false');
    } else if (window.location.hash === '#ns-map-enabled') {
      updateMapCookie('true');
    }
  }, [updateMapCookie]);

  const isMapEnabled = useMemo(() => {
    const isEnabledInMarket =
      !!countryCode && MAP_ENABLED_COUNTRIES.has(countryCode);

    return (
      isEnabledInMarket &&
      (mapCookie === 'true' || (mapCookie !== 'false' && isMapEnabledProp))
    );
  }, [countryCode, mapCookie, isMapEnabledProp]);

  return {
    isMapEnabled,
  };
};
